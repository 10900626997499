
// Overrides user components-variables
@import "../../bootstrap/functions";

@import "../variables/variables";
@import "../../bootstrap/variables";


// Google Color Palette defined: http://www.google.com/design/spec/style/color.html

$white: (
  "base": #FFFFFF,
);
$black: (
  "base": #000000,
);
$dark: (
  "base": theme-color("dark")
);
$light: (
  "base": theme-color("light")
);
$primary: (
  "lighten-5": lighten(theme-color("primary"), 25%),
  "lighten-4": lighten(theme-color("primary"), 20%),
  "lighten-3": lighten(theme-color("primary"), 15%),
  "lighten-2": lighten(theme-color("primary"), 10%),
  "lighten-1": lighten(theme-color("primary"), 5%),
  "base": theme-color("primary"),
  "darken-1": darken(theme-color("primary"), 5%),
  "darken-2": darken(theme-color("primary"), 10%),
  "darken-3": darken(theme-color("primary"), 15%),
  "darken-4": darken(theme-color("primary"), 20%),
  "accent-1":   #BDFDFF,
  "accent-2":   #8AFBFF,
  "accent-3":   #57FAFF,
  "accent-4":   #3DF9FF,
  "light"   :   #E2ECFF
);
$secondary: (
  "lighten-5": lighten(theme-color("secondary"), 25%),
  "lighten-4": lighten(theme-color("secondary"), 20%),
  "lighten-3": lighten(theme-color("secondary"), 15%),
  "lighten-2": lighten(theme-color("secondary"), 10%),
  "lighten-1": lighten(theme-color("secondary"), 5%),
  "base": theme-color("secondary"),
  "darken-1": darken(theme-color("secondary"), 5%),
  "darken-2": darken(theme-color("secondary"), 10%),
  "darken-3": darken(theme-color("secondary"), 15%),
  "darken-4": darken(theme-color("secondary"), 20%),
  "light"   : #E6EAEE
);
$success: (
  "lighten-5": lighten(theme-color("success"), 25%),
  "lighten-4": lighten(theme-color("success"), 20%),
  "lighten-3": lighten(theme-color("success"), 15%),
  "lighten-2": lighten(theme-color("success"), 10%),
  "lighten-1": lighten(theme-color("success"), 5%),
  "base": theme-color("success"),
  "darken-1": darken(theme-color("success"), 5%),
  "darken-2": darken(theme-color("success"), 10%),
  "darken-3": darken(theme-color("success"), 15%),
  "darken-4": darken(theme-color("success"), 20%),
  "accent-1":   #E1FFF1,
  "accent-2":   #AEFFD9,
  "accent-3":   #7BFFC1,
  "accent-4":   #62FFB5,
  "light"   :   #D2FFE8
);
$info: (
  "lighten-5": lighten(theme-color("info"), 25%),
  "lighten-4": lighten(theme-color("info"), 20%),
  "lighten-3": lighten(theme-color("info"), 15%),
  "lighten-2": lighten(theme-color("info"), 10%),
  "lighten-1": lighten(theme-color("info"), 5%),
  "base": theme-color("info"),
  "darken-1": darken(theme-color("info"), 5%),
  "darken-2": darken(theme-color("info"), 10%),
  "darken-3": darken(theme-color("info"), 15%),
  "darken-4": darken(theme-color("info"), 20%),
  "accent-1":   #FEFFFF,
  "accent-2":   #CBF5FF,
  "accent-3":   #98ECFF,
  "accent-4":   #7FE7FF,
  "light"   :   #CCF5F8
);
$warning: (
  "lighten-5": lighten(theme-color("warning"), 25%),
  "lighten-4": lighten(theme-color("warning"), 20%),
  "lighten-3": lighten(theme-color("warning"), 15%),
  "lighten-2": lighten(theme-color("warning"), 10%),
  "lighten-1": lighten(theme-color("warning"), 5%),
  "base": theme-color("warning"),
  "darken-1": darken(theme-color("warning"), 5%),
  "darken-2": darken(theme-color("warning"), 10%),
  "darken-3": darken(theme-color("warning"), 15%),
  "darken-4": darken(theme-color("warning"), 20%),
  "accent-1":   #FFF5EF,
  "accent-2":   #FFE5D8,
  "accent-3":   #FFF6F3,
  "accent-4":   #FFE3DA,
  "light"   :   #FFEED9
);
$danger: (
  "lighten-5": lighten(theme-color("danger"), 25%),
  "lighten-4": lighten(theme-color("danger"), 20%),
  "lighten-3": lighten(theme-color("danger"), 15%),
  "lighten-2": lighten(theme-color("danger"), 10%),
  "lighten-1": lighten(theme-color("danger"), 5%),
  "base": theme-color("danger"),
  "darken-1": darken(theme-color("danger"), 5%),
  "darken-2": darken(theme-color("danger"), 10%),
  "darken-3": darken(theme-color("danger"), 15%),
  "darken-4": darken(theme-color("danger"), 20%),
  "accent-1":   #FFEEF1,
  "accent-2":   #FFD6DB,
  "accent-3":   #FFECEE,
  "accent-4":   #FFD3D7,
  "light"   :   #FFDEDE
);

$colors: (
  "white": $white,
  "black": $black,
  "dark": $dark,
  "light": $light,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger
);
